import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function FeatureCard({ icon, iconImage, title, children }) {
  return (
    <div className="md:my-6 md:col-span-4 text-center max-w-xs mx-auto">
      {icon && <FontAwesomeIcon className="text-gray-800 text-6xl lg:text-7xl icon-image" icon={icon}></FontAwesomeIcon>}
      {iconImage && <img src={iconImage} className="mx-auto icon-image" alt={title} title={title}/>}
      <h3 className="text-3xl font-extrabold text-gray-900 mt-4 md:mt-6">{title}</h3>
      <p className="text-lg lg:text-xl text-gray-500 mt-3">{children}</p>
    </div>
  );
}