import React, { useEffect, useRef } from "react"

import appStoreButtonSVG from "../images/app-store-button.svg"
import appStoreWhiteButtonSVG from "../images/app-store-button-white.svg"
import { trackLink } from "../utils/analytics"

export const AppStoreButton = ({ product, location, href, alt = 'Download on Apple App Store button', title = 'Download on Apple App Store button', className, theme }) => {

  const link = useRef(null);

  useEffect(() => {
    trackLink(link, "AppStore Visited", {
      product: product,
      location: location
    })
  });

  return (
    <div className={`${className || ''}`}>
      <a ref={link} href={href}>
        <img className="mx-auto h-12 md:h-14 xl:h-18" src={theme == "white" ? appStoreWhiteButtonSVG : appStoreButtonSVG} alt={alt} title={title}/>
      </a>
    </div>
  )
}