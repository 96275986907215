import React from "react"
import { Link } from "gatsby"

export function FaqList({ children }) {
  return (
    <div className="bg-gray-50 md:grid md:grid-cols-2">
      {children}
    </div>)
}

export function FaqListItem({ faq }) {
  return (
    <article className="relative group px-4 py-6">
      { faq.url && <Link className="absolute left-0 top-0 right-0 bottom-0 opacity-5 hover:bg-gray-500" to={faq.url}></Link> }

      <div className="post-wrapper">
        <header className="post-header">
          <h3 className="post-title text-lg text-gray-600 group-hover:text-gray-900 mb-2">{faq.frontmatter.title}</h3>
        </header>
        <p className="post-excerpt text-gray-500">
          {faq.excerpt}
        </p>
      </div>
    </article>
  )
}

