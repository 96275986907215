import React, { useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import { useInView } from "react-intersection-observer"

export function Video({ src, className, noshadow }) {
  const [videoRef, inView, entry] = useInView({
    threshold: 0.75
  })
  
  useEffect(() => {
    // console.log("inView updated: ", inView, src, entry && entry.target)
    if (!entry || !entry.target) {
      return
    }

    if (inView) {
      entry.target.play()
    } else {
      entry.target.pause()
    }
  }, [inView])

  return (
    // help/cullai/getting-started/quick-start-from-sd-card/step1-import-photos-from-sd-card.mov
    <StaticQuery
      query={graphql`
        query VideoQuery {
          allFile(filter: {sourceInstanceName: {eq: "videos"}}) {
            edges {
              node {
                id
                relativePath
                videoH264(maxHeight: 720) {
                  path
                }
              }
            }
          }
        }         
      `}
      render={data => {
        const video = data.allFile.edges.find(({ node }) => node.relativePath === src).node

        return (
          <div className={`rounded md:rounded-lg align-middle border-none overflow-hidden ${noshadow ? '' : 'macos-shadow'} ${className || ''}`}>
            <video src={`${video.videoH264.path}#t=0.01`} ref={videoRef} className="rounded md:rounded-lg" loop muted playsInline preload="metadata">
              <source src={`${video.videoH264.path}#t=0.01`} type="video/mp4" />
            </video>
          </div>
        )
      }}
    />
  );
}